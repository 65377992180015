import React from 'react';
import { Stack, Chip, Icon, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useRouter, useSmall } from '../../../hooks';

const StatusStack = ({ statuses, loading }) => {
  const theme = useTheme();
  const isSmall = useSmall();
  const router = useRouter();
  const handleClick = link => {
    router.push(link);
  };
  return (
    <Stack direction="row" spacing={0} sx={{ flexWrap: 'wrap', width: 'fit-content' }}>
      {loading ? (
        <Skeleton height={20} width="90%" animation="wave" />
      ) : (
        <>
          {statuses &&
            statuses.map(x => {
              const displayElement = (
                <Chip
                  size="small"
                  label={x?.status}
                  sx={{
                    color: theme?.palette[x?.color]?.main,
                    backgroundColor: theme?.palette[x?.color]?.background,
                    borderColor: theme?.palette[x?.color]?.main,
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    mr: theme.spacing(0.5),
                    mb: theme.spacing(0.5),
                  }}
                  onClick={x?.link ? () => handleClick(x?.link) : null}
                  icon={
                    <Icon
                      data-nosnippet
                      sx={{
                        color: `${theme?.palette[x?.color]?.main} !important`,
                      }}
                    >
                      {x?.icon}
                    </Icon>
                  }
                />
              );

              return <>{displayElement}</>;
            })}
        </>
      )}
    </Stack>
  );
};

export default StatusStack;
