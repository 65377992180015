import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormControl, Switch, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import styles from './style';
import { getLocalStorage } from '../../../storage/localStorage';
import { useOldPipelineDataStorage, domain } from '../../../utils/variables';
import { useFeatureToggle, useProcessCookies } from '../../../hooks';
import { parseBool } from '../../../utils/functions';

const useStyles = makeStyles(styles);
const PipelineSwitcher = ({ removeInnerPadding = false, ...props }) => {
  const doFeatureToggle = useFeatureToggle();
  const enableUseOldPipelineData = doFeatureToggle(`enableUseOldPipelineData`);
  const processCookies = useProcessCookies();
  const oldPipelineValue = parseBool(getLocalStorage(useOldPipelineDataStorage));
  const [currentValue, setCurrentValue] = useState(oldPipelineValue);
  const classes = useStyles({ removeInnerPadding });

  const handleSwitch = event => {
    const isChecked = !!event.target.checked;
    const cookieValues = {
      [useOldPipelineDataStorage]: isChecked,
    };

    processCookies({ cookieValues });
    setCurrentValue(isChecked);
  };

  if (!enableUseOldPipelineData || domain?.env === 'production') return null;

  return (
    <Tooltip title="Switch on to use the old pipeline data.">
      <FormControl component="fieldset" className={classes.root}>
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={currentValue}
              onChange={handleSwitch}
              id={props.id}
            />
          }
          label="Use Old Data Pipeline"
          classes={{ root: classes.alignCenter, label: classes.switchLabel }}
          labelPlacement="start"
        />
      </FormControl>
    </Tooltip>
  );
};

export default PipelineSwitcher;

PipelineSwitcher.propTypes = {
  onChangePortfolio: PropTypes.func,
};
PipelineSwitcher.defaultProps = {
  onChangePortfolio: () => {},
};
