export const getLocalStorage = (storageName, { isObject, defaultValue } = {}) => {
  let value = localStorage.getItem(storageName) || defaultValue;
  if (isObject) {
    try {
      value = JSON.parse(value);
    } catch (e) {
      // console.log('e: ', e);
      value = null;
    }
  }
  // if (typeof value !== 'object') value = null;
  // // console.log('alpha2: ', alpha2);
  return value;
};

export const removeLocalStorage = storageName => {
  if (localStorage.getItem(storageName)) {
    localStorage.removeItem(storageName);
  }
  return null;
};

export const setLocalStorage = (storageName, value, { isObject } = {}) => {
  if (value) {
    // // console.log('alpha2: ', alpha2);
    let storedValue = value;
    if (isObject) {
      try {
        storedValue = JSON.stringify(storedValue);
      } catch (e) {
        storedValue = null;
        // console.log('e: ', e);
      }
    }
    if (storedValue) {
      localStorage.setItem(storageName, storedValue);
    }
  }
  return null;
};

export default {
  setLocalStorage,
  removeLocalStorage,
  getLocalStorage,
};
