const styles = theme => ({
  root: ({ removeInnerPadding }) => ({
    flexGrow: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(1),
    paddingTop: removeInnerPadding ? 0 : theme.spacing(1),
  }),
  alignCenter: {
    verticalAlign: 'middle',
    justifyContent: 'center',
    alignItems: 'center',
  },
  switchLabel: {
    height: 24,
  },
});

export default styles;
